.dib-sidenav {
  background-color: #fff;
}
.dib-item-link {
  text-decoration: none;
  display: block;
  padding: 0 16px 0 32px;
  line-height: 60px;
  font-size: 14px;
  color: #424242 !important;
  text-align: left;
  margin: 0;
  min-height: 100%;
  height: 100%;
  text-transform: capitalize;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
dib-sidenav > md-sidenav > md-list > md-list-item > button {
  margin: 0 !important;
  width: 100% !important;
  min-height: 100% !important;
  height: 100% !important;
}
dib-sidenav > md-sidenav > md-list > md-list-item.md-no-proxy {
  padding: 0px;
}
input[type="button"] {
  outline: none;
}
input[type="button"]::-moz-focus-inner {
  border: 0;
}
a {
  outline: none;
}
md-list-item .md-button:hover,
.md-button:not([disabled]).md-focused {
  background-color: transparent;
}
md-list-item {
  transition: all 0.8s ease-out;
  -webkit-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
  transition: all 0.8s cubic-bezier(0.68, -0.55, 0.265, 1.55) !important;
}
md-sidenav {
  -webkit-transition: all 0.8s ease-out;
  transition: all 0.8s ease-out;
}
md-sidenav,
md-backdrop {
  position: fixed !important;
}
