.version {
  color: #546e7a;
}
.language {
  color: #00897b;
}
.codedBy {
  color: #039be5;
}
.font-600 {
  font-weight: 600;
}
