.LoginForm-inputContainer {
  margin-top: 10px;
  margin-bottom: 0px;
  width: 80%;
  min-width: 300px;
}
.LoginForm-submit {
  margin-bottom: 25px;
  margin-left: 0;
  width: 100%;
  padding: 5px 0;
}
.forgot-link {
  text-align: center;
}
.forgot-link a {
  text-decoration: none;
}
.register-link {
  text-align: center;
}
.register-link a {
  text-decoration: none;
}
#register-divider {
  margin-top: 10px;
  margin-bottom: 5px;
}
