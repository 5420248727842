.counter-users {
  color: #ffb300;
  font-size: 30px;
  text-align: center;
  line-height: 24px;
  font-family: "Raleway", sans-serif;
  margin-left: 0 !important;
  margin: 0 25px;
  padding: 0 0 0 0;
  font-weight: normal;
  letter-spacing: 1px;
}
