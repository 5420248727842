.about-wrapper {
  background-color: #121111;
  width: 100%;
  height: 200px;
}
.about-header {
  height: 150px;
}
.about-body {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}
.padding-left {
  padding-left: 10px;
}
.btn-small {
  font-size: 14px !important;
  padding: 6px 10px 6px !important;
}
