.bib-desc-wrapper {
  margin: 30px auto 30px;
  color: #707070;
  font-size: 16px;
  font-weight: normal;
  -webkit-font-smoothing: antialiased;
}
.bib-papers {
  padding: 5px 0;
  text-align: left;
  line-height: 26px;
}
