.yt-card.yt-card-has-padding {
  padding: 15px;
}
.yt-card {
  margin: 10px;
  border: 0;
  background: #fff;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.preview-ctn {
  min-height: 480px;
}
.thumb-ctn {
  min-height: 640px;
}
.preview-card {
  padding: 0 4px;
  width: 100%;
  min-height: 365px;
  object-fit: cover;
  background-color: transparent;
}
.panzoomwidget {
  width: 20px;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.panzoomwidget .zoom-button {
  text-align: center;
  height: 20px;
  width: 100%;
  background-color: white;
  cursor: hand;
  border: 1px solid black;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
  -webkit-box-shadow: 2px 2px 2px 0 rgba(0, 0, 0, 0.2);
  box-shadow: 2px 2px 2px 0 rgba(0, 0, 0, 0.2);
}
.panzoomwidget .zoom-button:hover {
  background-color: #ccc;
}
.panzoomwidget .zoom-level {
  width: 100%;
  border-left: 2px solid black;
  margin-left: 10px;
}
.panzoomwidget .zoom-slider {
  position: relative;
  cursor: hand;
}
.panzoomwidget .zoom-slider-widget {
  position: absolute;
  width: 20px;
  background-color: white;
  border: 1px solid black;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  -webkit-box-shadow: 2px 2px 2px 0 rgba(0, 0, 0, 0.5);
  box-shadow: 2px 2px 2px 0 rgba(0, 0, 0, 0.2);
}
.pan-zoom-frame {
  background-color: #424242;
}
.thumb-view {
  max-width: 135px;
  max-height: 195px;
}
md-list-item,
md-list-item .md-list-item-inner {
  justify-content: center !important;
}
.md-subheader .md-subheader-inner {
  color: #424242 !important;
}
.md-list-item-inner > div > img {
  border: 2px solid #bbbbbb;
}
.checked {
  border: 4px solid #ffd54f !important;
}
.align-left {
  text-align: left;
  font-size: 15px;
}
