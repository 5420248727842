.publications-header {
  height: 150px;
}
.publications-body {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}
li {
  text-align: justify;
}
