.bg-grey {
  background-color: #2d2d30;
}
.bg-white {
  background-color: #eeeeee !important;
}
.bg-primary {
  background-color: #ffd54f !important;
}
.bg-primary-g {
  background-color: #81c784 !important;
}
.bg-primary-900-g {
  background-color: #43a047 !important;
}
.bg-primary-900 {
  background-color: #ffb300 !important;
}
.bg-amber-200 {
  background-color: #ffe082 !important;
}
.bg-green-200 {
  background-color: #a5d6a7 !important;
}
.card-gt {
  margin: 0;
  width: 100%;
  height: 100%;
}
.no-box-shadow {
  box-shadow: none;
  background-color: #e6e6e6;
  border: 1px solid #cccccc;
}
.basic-border {
  border-top: 1px solid #cccccc;
  border-right: 1px solid #cccccc;
  border-bottom: 1px solid #cccccc;
}
.mod-card {
  padding: 0 4px;
  width: 100%;
  height: 112.5px;
  object-fit: cover;
  background-color: transparent;
}
.color-primary {
  color: #ffd54f;
  font-weight: bold;
}
.nav-texture .md-nav-item:hover:not(.md-active),
.nav-gt .md-nav-item:hover:not(.md-active) {
  opacity: 0.8;
  color: #888888 !important;
}
.nav-gt .md-nav-bar,
.nav-texture .md-nav-bar {
  border-width: 0;
  border-style: none;
}
.nav-gt .md-button._md-nav-button.md-unselected,
.nav-texture .md-button._md-nav-button.md-unselected {
  padding: 0px 12px;
  background-color: #e6e6e6;
}
.nav-gt .md-nav-item .md-active {
  background-color: #ffd54f;
}
.nav-texture .md-nav-item .md-active {
  background-color: #81c784;
}
.my-row {
  overflow: auto;
  width: 100%;
}
md-whiteframe {
  background-color: #e6e6e6;
}
.row__inner {
  font-size: 0;
  white-space: nowrap;
  margin: 56.25px 0;
}
.tile {
  position: relative;
  display: inline-block;
  width: 200px;
  height: 112.5px;
  margin-right: 10px;
  font-size: 20px;
  cursor: pointer;
  border: 1px solid #bdbdbd;
}
.tile__img {
  width: 200px;
  height: 112.5px;
  -o-object-fit: cover;
  object-fit: cover;
}
.tile__details {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  font-size: 10px;
  opacity: 0;
  transition: 450ms opacity;
}
.tile__details:after {
  position: absolute;
  top: 50%;
  left: 50%;
  display: block;
}
.tile__details:after {
  margin-top: -25px;
  margin-left: -25px;
  width: 50px;
  height: 50px;
  border: 3px solid #ecf0f1;
  line-height: 50px;
  text-align: center;
  border-radius: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1;
}
.tile__details {
  outline: 0;
}
.tile:hover {
  border: none;
}
.tile:hover .tile__details {
  opacity: 1;
}
.tile.check-primary {
  border: 2px solid #ffd54f;
}
.tile.check-txt {
  border: 2px solid #81c784;
}
.tile:hover .fname {
  opacity: 0;
}
.tile__title {
  position: absolute;
  bottom: 0;
  padding: 10px;
  color: #E9F0F3;
}
.tile__title.mod-texture {
  color: #fff;
}
.mod-btn-1 {
  position: absolute;
  bottom: 0;
  padding: 10px 0px;
  right: 5px;
  width: 25%;
  text-align: right;
  color: #fff;
}
.mod-btn-1:hover {
  color: #ffd54f;
}
.mod-btn-1.mod-texture {
  color: #fff;
}
.mod-btn-1.mod-texture:hover {
  color: #81c784;
}
.mod-btn-2 {
  position: absolute;
  bottom: 0;
  width: 100%;
  text-align: center;
  padding: 10px 0;
  color: #fff;
}
.mod-btn-2:hover {
  color: #ffd54f;
}
.mod-btn-2.mod-texture {
  color: #fff;
}
.mod-btn-2.mod-texture:hover {
  color: #81c784;
}
.icon-green > md-icon > svg {
  fill: #81c784;
}
.icon-primary > md-icon > svg {
  fill: #ffd54f;
}
.tile .fname {
  opacity: 1;
}
.fname {
  bottom: 0;
  width: 100%;
  display: block;
  font-size: 20px;
  text-align: center;
  z-index: 2;
  opacity: 1;
  color: #333333;
}
.row__inner:hover {
  -webkit-transform: translate3d(-50px, 0, 0);
  transform: translate3d(-50px, 0, 0);
}
.row__inner:hover .tile {
  opacity: 0.3;
}
.row__inner:hover .tile:hover {
  -webkit-transform: scale(1.5);
  transform: scale(1.5);
  opacity: 1;
}
.tile:hover ~ .tile {
  -webkit-transform: translate3d(100px, 0, 0);
  transform: translate3d(100px, 0, 0);
}
.margin-l-70 {
  margin-left: 66.66666667px;
}
.margin-r-70 {
  margin-right: 66.66666667px;
}
.bd-radius-10 {
  border-radius: 0 10px 10px 0;
}
.bd-card-1 {
  border-top: 3px solid #ffd54f;
  border-right: 3px solid #ffd54f;
  border-bottom: 3px solid #ffd54f;
}
.bd-card-2 {
  border-top: 3px solid #81c784;
  border-right: 3px solid #81c784;
  border-bottom: 3px solid #81c784;
}
md-tabs md-tab-item {
  padding: 0px 12px;
  background-color: #e6e6e6;
  border-right: 2px solid #ffffff;
  text-transform: capitalize !important;
}
.wrapper {
  margin: 0 auto 30px;
  padding: 30px 15px;
}
md-tabs.md-default-theme md-ink-bar,
md-tabs md-ink-bar {
  background: #ffb300;
}
md-tabs.md-default-theme .md-tab.md-active,
md-tabs .md-tab.md-active {
  color: #333333;
}
.theadTrRow th.column {
  background-color: #e6e6e6;
  height: 40px;
  font-weight: bold;
  color: #424242;
}
.progress-gt {
  position: absolute;
  background-color: #e6e6e6;
  width: 100%;
  height: 100%;
}
.mdtTableContainer .rightAlignedColumn {
  text-align: center;
}
.button-link {
  text-decoration: none;
  color: rgba(0, 0, 0, 0.87) !important;
}
.alg-link {
  text-decoration: none;
  color: #696969 !important;
}
.alg-link.has-link {
  color: #212121 !important;
}
.alg-link.has-link:hover {
  color: #888888 !important;
}
.dib-table,
.dib-table-t2 {
  text-align: center;
  width: 100%;
  border-bottom: 1px solid #d4d4d4;
}
.dib-table .ui-grid-cell-contents.ui-grid-header-cell-primary-focus,
.dib-table-t2 .ui-grid-cell-contents.ui-grid-header-cell-primary-focus {
  padding: 0px;
}
.dib-table .ui-grid-header-cell .sortable,
.dib-table-t2 .ui-grid-header-cell .sortable {
  height: 56px;
}
.dib-table .ui-grid-header-cell,
.dib-table-t2 .ui-grid-header-cell {
  border: 0px;
  color: #fff;
}
.dib-table .ui-grid-header .ui-grid-top-panel,
.dib-table-t2 .ui-grid-header .ui-grid-top-panel {
  background: #333333;
  font-weight: bold;
  color: #424242;
  font-size: 13px;
  line-height: 56px;
}
.ui-grid-tree-header-row {
  font-weight: normal !important;
}
.dib-table .ui-grid-viewport,
.dib-table-t2 .ui-grid-viewport {
  font-size: 15px;
}
.dib-table .ui-grid-viewport .ui-grid-cell,
.dib-table-t2 .ui-grid-viewport .ui-grid-cell {
  height: 48px;
  line-height: 48px;
  border-right: 0px;
  border-bottom: 1px solid;
  border-color: #dddddd;
}
:focus {
  outline: 0;
}
.dib-table .ui-grid-menu .ui-grid-menu-inner,
.dib-table-t2 .ui-grid-menu .ui-grid-menu-inner {
  font-size: 13px;
  background: #f3f3f3;
  border: 1px solid #d4d4d4;
  position: relative;
  white-space: nowrap;
  -webkit-border-radius: 0px;
  -moz-border-radius: 0px;
  border-radius: 0px;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}
.ui-grid-menu .ui-grid-menu-inner ul li button.ui-grid-menu-item-active {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  background-color: #ffd54f;
}
.ui-grid-menu .ui-grid-menu-inner ul li button:focus {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}
.ui-grid-menu .ui-grid-menu-inner ul li button:hover {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  background: #ffd54f;
}
.no-animate,
.ui-grid-menu-mid {
  -webkit-transition: none !important;
  transition: none !important;
}
.dib-table-t2 .ui-grid-row:first-child .ui-grid-cell {
  background-color: #333333;
}
.dib-table-t2 .ui-grid-row:first-child .ui-grid-cell .ui-grid-cell-contents {
  color: #fff !important;
  font-weight: bold !important;
}
.dib-table-t2 .ui-grid-row:first-child .ui-grid-cell .ui-grid-cell-contents .alg-link {
  color: #fff !important;
  font-weight: bold !important;
}
.dib-table-t2 .ui-grid-row:first-child .ui-grid-cell:first-child .ui-grid-cell-contents {
  text-align: right;
}
.dib-table-t2 .ui-grid-row:first-child .ui-grid-cell:nth-child(2) .ui-grid-cell-contents {
  text-align: left;
}
.dib-table-t2 .ui-grid-canvas {
  padding: 0 !important;
}
.ui-grid-column-menu-button-last-col {
  margin-right: 0px !important;
}
.btn-accent {
  background-color: #4DB6AC !important;
}
.btn-accent-2 {
  background-color: #80CBC4 !important;
}
