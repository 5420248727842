.competitions-header {
  height: 150px;
}
.competitions-body {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}
li {
  margin: 15px 0;
}
