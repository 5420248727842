.terms-itens {
  margin-top: 30px;
  margin-bottom: 150px;
}
.terms-itens ul {
  margin: 15px 0 0 0;
  padding: 0 25px;
  list-style: none;
}
.terms-itens ul li {
  padding: 5px 0;
  line-height: 26px;
}
.terms-itens ul li span {
  color: red;
}
