#footer {
  position: relative;
  bottom: 0;
  width: 100%;
}
.Footer {
  text-align: center;
  border-top: 1px solid #E9F0F3;
  font-weight: 300;
}
.footer-head {
  text-decoration: none;
  padding: 0 12px;
  display: inline-block;
  font-family: "Raleway", sans-serif;
  font-size: 18px;
  font-weight: normal;
  color: #fff !important;
}
.dib-logo-footer {
  height: 50px;
  width: 190px !important;
  padding: 0 !important;
}
.padding-row {
  padding: 10px 40px 10px 40px;
  opacity: 0.8;
}
.dib-logo-footer svg > text {
  fill: #fff !important;
}
.dib-logo-footer svg > rect {
  fill: #333333 !important;
}
.Footer-text {
  padding-top: 10px;
  opacity: 0.8;
}
.footer-wrapper {
  margin: auto;
}
.black-wrapper {
  background: #2d2d30;
  color: #bdbdbd !important;
}
.sub-footer {
  background: #29292c;
  padding: 15px 0;
  font-size: 14px;
}
.sub-footer a {
  color: #fff !important;
  text-decoration: none;
}
.footer-link {
  padding: 2px 0;
}
.footer-link a {
  text-decoration: none;
  color: #fff !important;
  font-weight: 400;
  opacity: 0.8;
}
.Footer-link:hover {
  text-decoration: underline;
}
.Footer-logo {
  width: 49px;
  height: 46px;
}
.last-updated {
  font-weight: bold;
  color: white;
}
