body {
  font-family: "Source Sans Pro", sans-serif;
  margin: 0;
  padding: 0;
  height: 100%;
  font-size: 1.25rem;
  color: #424242;
}
md-content {
  color: #424242 !important;
}
#container {
  min-height: 100%;
  position: relative;
}
#main {
  padding-top: 68px;
}
.Landing {
  height: 100%;
}
.Landing-cover {
  background-image: url(../../img/svg/pbi-pattern.png), linear-gradient(to top, #f2c811, #ffd54f);
  width: 100%;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;
  color: #000;
  overflow: hidden;
  position: relative;
}
.what-is {
  padding: 8px;
  text-align: center;
  line-height: 34px;
}
.Landing-heading {
  font-family: "Raleway", sans-serif;
  font-size: 2.3rem !important;
  font-weight: normal;
  letter-spacing: 3px;
  margin-bottom: 15px;
  text-align: center;
  padding: 15px;
  background-color: #ffd54f;
  color: #000;
  font-weight: 700;
  text-transform: uppercase;
}
.Landing-heading.main {
  background-color: transparent !important;
}
.Landing-subtitle {
  text-align: center;
  opacity: 0.9;
  color: #333333;
  font-size: 1.25rem;
}
.summary-card {
  font-size: 1.25rem;
  margin: 0 25px;
}
.plan {
  text-align: center;
  padding: 10px 0;
  display: block;
  width: 365px;
  position: relative;
  top: 0;
  background: #fff;
  overflow: hidden;
  margin-bottom: 30px;
  -webkit-border-radius: 7px;
  -moz-border-radius: 7px;
  -ms-border-radius: 7px;
  border-radius: 7px;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}
.plan h3 {
  font-family: "Raleway", sans-serif;
  font-weight: normal;
  text-transform: uppercase;
  font-size: 22px;
  line-height: 40px;
  padding: 15px 15px;
  margin-top: 0;
  color: #424242;
}
.section {
  background-color: #eee;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;
  position: relative;
}
.section {
  display: block;
  padding: 10px 0;
  width: 100%;
}
.section-title {
  margin-top: 30px;
  font-family: "Raleway", sans-serif;
  color: #424242;
  font-size: 1.2rem;
  font-weight: bold;
  text-transform: uppercase;
  position: relative;
  display: block;
  padding-bottom: 20px;
}
.section-title.normalize {
  font-size: 1.875rem;
  text-transform: inherit;
}
.section-dataset {
  background-color: #121111 !important;
}
.section-dataset .section-title {
  color: #f4f4f4 !important;
}
.section-partner {
  background-color: #fff;
}
.section-partner .partner-icons {
  padding: 0px;
}
.plan h4 {
  font-size: 18px;
  line-height: 0px;
  font-family: "Raleway", sans-serif;
  color: #424242;
  padding: 0 0 0 0;
  font-weight: normal;
}
.plan ul {
  margin: 15px 0 0 0;
  padding: 0 25px;
  list-style: none;
}
.plan ul li {
  padding: 0px 0;
  line-height: 20px;
}
.plan .select {
  padding: 27px 0;
}
.btn-color-gold {
  width: 50%;
  letter-spacing: 1px;
}
.plan h4 .amount {
  font-size: 45px;
  padding-bottom: 10px;
  display: block;
  text-align: center;
}
.plan h4 .amount span {
  vertical-align: super;
  font-size: 20px;
  font-weight: 400;
}
.plan .features {
  overflow-x: hidden;
  color: #707070;
  font-size: 15px;
  font-weight: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;
}
.plan h4 span {
  color: #ffb300;
}
.Landing-features {
  text-align: center;
}
.Landing-featuresMainDescription {
  width: 70%;
  margin: 0 auto 30px;
  color: #333333;
  font-weight: 300;
}
.Landing-featuresEntry {
  width: 176px;
  height: 176px;
  border-radius: 50%;
  margin: 40px auto 20px;
}
.Landing-featuresEntry-icon {
  height: 100%;
}
.Landing-featuresDescription {
  padding-top: 10px;
  padding-left: 50px;
  padding-right: 50px;
  font-weight: 300;
  color: #333333;
}
/*
This is a terrible temporary hack,
to fix layout issues with flex on iOS (chrome & safari)
Make sure to remove this when you modify this demo
*/
@media only screen and (max-width: 950px) {
  .iOS-hack .flex-100,
  .iOS-hack .flex-50,
  .iOS-hack .flex-33 {
    flex: none !important;
  }
}
.new-btn {
  background-color: transparent;
  font-size: 16px;
  font-weight: 600;
  text-transform: uppercase;
  color: white !important;
}
.new-btn:hover {
  background-color: #000;
  color: #fff !important;
  fill: #fff;
  text-decoration: none;
}
.summary-card .plan:hover,
.summary-card .plan:focus {
  -webkit-box-shadow: 7px 14px 42px 3px rgba(0, 0, 0, 0.4);
  -moz-box-shadow: 7px 14px 42px 3px rgba(0, 0, 0, 0.4);
  -ms-box-shadow: 7px 14px 42px 3px rgba(0, 0, 0, 0.4);
  -o-box-shadow: 7px 14px 42px 3px rgba(0, 0, 0, 0.4);
  box-shadow: 7px 14px 42px 3px rgba(0, 0, 0, 0.4);
  text-decoration: none;
  top: -15px;
}
.button-featured {
  background-color: #f2c811 !important;
  border: 2px solid #f2c811 !important;
  width: 50%;
  letter-spacing: 1px;
}
.button-featured:hover {
  background-color: #fad227 !important;
  border: 2px solid #fad227 !important;
  color: #000 !important;
  fill: #000;
}
.notify-badge {
  right: -5px !important;
}
.black-wrapper {
  background: #2d2d30;
  color: #bdbdbd !important;
}
.middle-footer {
  max-width: none !important;
}
