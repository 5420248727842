.Register-formContainer {
  padding: 90px;
}
.Register-formContainer h1 {
  color: #424242;
  margin: 0 25px;
  padding: 0 0 25px 0;
  font-family: 'Raleway', sans-serif;
  font-size: 1.825rem !important;
  font-weight: normal;
  letter-spacing: 1px;
  margin-bottom: 15px;
  text-align: center;
  text-transform: uppercase;
}
