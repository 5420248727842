time.date-icon {
  font-size: 0.8em;
  display: block;
  position: relative;
  width: 7em;
  height: 7em;
  background-color: #fff;
  border-radius: 0.6em;
  box-shadow: 0 1px 0 #bdbdbd, 0 2px 0 #fff, 0 3px 0 #bdbdbd, 0 4px 0 #fff,
        0 5px 0 #bdbdbd, 0 0 0 1px #bdbdbd;
  overflow: hidden;
}
time.date-icon * {
  display: block;
  width: 100%;
  font-size: 1em;
  font-weight: bold;
  font-style: normal;
  text-align: center;
}
time.date-icon strong {
  position: absolute;
  top: 0;
  color: #fff;
  text-transform: uppercase;
}
time.date-icon em {
  position: absolute;
  bottom: -0.5em;
}
time.date-icon.feb em {
  color: #ff9800;
}
time.date-icon.feb strong {
  background-color: #ff9800;
  border-bottom: 1px dashed #f57c00;
  box-shadow: 0 2px 0 #ff9800;
}
time.date-icon.april em {
  color: #2196f3;
}
time.date-icon.april strong {
  background-color: #2196f3;
  border-bottom: 1px dashed #1976d2;
  box-shadow: 0 2px 0 #2196f3;
}
time.date-icon.may em {
  color: #009688;
}
time.date-icon.may strong {
  background-color: #009688;
  border-bottom: 1px dashed #00796b;
  box-shadow: 0 2px 0 #009688;
}
time.date-icon.sep em {
  color: #9c27b0;
}
time.date-icon.sep strong {
  background-color: #9c27b0;
  border-bottom: 1px dashed #7b1fa2;
  box-shadow: 0 2px 0 #9c27b0;
}
time.date-icon span {
  font-size: 2.5em;
  letter-spacing: -0.05em;
  padding-top: 1.2em;
  color: #2f2f2f;
}
.text-content {
  text-align: justify;
}
.heading {
  font-family: "Raleway", sans-serif;
  font-weight: normal;
  letter-spacing: 3px;
  text-align: center;
  padding: 15px;
  background-color: #ffd54f;
  color: #000;
  font-weight: 600;
  margin-bottom: 50px;
}
.heading.landing {
  margin: 4px;
  transition: 250ms background-color;
  line-height: 20px;
  font-size: 20px;
}
.heading.landing:hover {
  background-color: #ffe082 !important;
}
.about-wrapper.doceng21 {
  height: 370px;
  background-image: url(/img/competitions/limerick_cropped.jpg), linear-gradient(to top, #f2c811, #ffd54f);
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;
}
.md-card-dates md-card-header-text {
  text-align: justify;
}
@media (min-width: 1280px) {
  .auto-margin {
    max-width: 1366px;
    margin: 0 auto;
  }
}
.organizers {
  margin-bottom: 50px;
}
.item {
  position: relative;
  padding-top: 20px;
  display: inline-block;
}
.notify-badge {
  position: absolute;
  right: -20px;
  top: 10px;
  background: red;
  text-align: center;
  border-radius: 30px 30px 30px 30px;
  color: white;
  padding: 5px 10px;
  font-size: 20px;
}
.competition-link:hover::after {
  content: "";
  position: absolute;
  left: 15%;
  right: 15%;
  bottom: 0;
  border: 1px solid black;
}
.comp-btn {
  background-color: transparent;
  font-size: 16px;
  font-weight: 600;
  text-transform: uppercase;
}
.comp-btn:hover {
  background-color: #000;
  color: #fff !important;
  fill: #fff;
  text-decoration: none;
}
.call-comp-btn {
  padding: 10px 4px 10px 10px;
}
