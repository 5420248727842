.plan > h3 {
  margin-bottom: 0;
  padding-bottom: 0;
}
.paper-card {
  margin-left: 20px;
  margin-right: 20px;
  margin-top: 10px;
  font-size: 9pt;
  text-align: justify;
}
